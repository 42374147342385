<template>
    <el-container>
      <el-main>
        <el-row>
          <el-col :span="24">
            <h3 class="tit">按行政等级</h3>
            <el-table
              size="small "
              :data="total_glxz_xzdj"
              border
              style="width: 100%"
            >
              <el-table-column
                prop="xzdj"
                :formatter="xzdj_formatter"
                width="120"
                label="行政等级"
              >
              </el-table-column>
              <el-table-column prop="lc" width="170" label="里程（公里）">
              </el-table-column>
              <el-table-column
                :formatter="percent_formatter"
                label="占总里程比例"
              >
              </el-table-column>
            </el-table>
          </el-col>
          <el-col :span="24">
            <h3 class="tit">按技术等级</h3>
            <el-table
              size="small "
              :data="total_glxz_jsdj"
              border
              style="width: 100%"
            >
              <el-table-column prop="jsdjmc" width="120" label="技术等级">
              </el-table-column>
              <el-table-column prop="lc" label="里程（公里）" width="170">
              </el-table-column>
              <el-table-column
                :formatter="percent_formatter"
                label="占总里程比例"
              >
              </el-table-column>
            </el-table>
          </el-col>
          <el-col :span="24">
            <h3 class="tit">等级公路占比统计（国省道）</h3>
            <el-table
              size="small "
              :data="total_glxz_gsdjgl"
              border
              style="width: 100%"
            >
              <el-table-column
                :formatter="xzdj_formatter"
                width="120"
                label="公路等级"
              >
              </el-table-column>
              <el-table-column prop="lc" width="130" label="里程（公里）">
              </el-table-column>
              <el-table-column prop="djgl" width="130" label="其中二级以上">
              </el-table-column>
              <el-table-column
                :formatter="gsgl_percent_formatter"
                label="占总里程比例"
              >
              </el-table-column>
            </el-table>
          </el-col>
          <el-col :span="24">
            <h3 class="tit">等级公路占比统计（农村公路）</h3>
            <el-table
              size="small "
              :data="total_glxz_xycdjgl"
              border
              style="width: 100%"
            >
              <el-table-column
                :formatter="xzdj_formatter"
                width="120"
                label="公路等级"
              >
              </el-table-column>
              <el-table-column prop="lc" width="130" label="里程（公里）">
              </el-table-column>
              <el-table-column prop="djgl" width="130" label="其中等级公路">
              </el-table-column>
              <el-table-column
                :formatter="xycgl_percent_formatter"
                label="占总里程比例"
              >
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </el-main>
    </el-container>
</template>
<script>
export default {
  props: [
    "total_glxz_xzdj",
    "total_glxz_jsdj",
    "total_glxz_gsdjgl",
    "total_glxz_xycdjgl",
  ],
  methods: {
    gsgl_percent_formatter(row) {
      return parseFloat((row.djgl / row.lc) * 100).toFixed(1) + "%";
    },
    xycgl_percent_formatter(row) {
      return parseFloat((row.djgl / row.lc) * 100).toFixed(1) + "%";
    },
    percent_formatter(row) {
      return parseFloat((row.lc / row.zlc) * 100).toFixed(1) + "%";
    },
    xzdj_formatter(row) {
      switch (row.xzdj) {
        case "G":
          return "国道";
        case "S":
          return "省道";
        case "X":
          return "县道";
        case "Y":
          return "乡道";
        case "Z":
          return "专用公路";
        case "C":
          return "村道";
      }
    },
  },
};
</script>
