<template>
    <div>
        <el-container class="ova-y">
            <el-main class="p0">
                <el-row>
                    <el-col :span="24">
                        <div class="box" ref="init">
                            <div class="box-content">
                                <el-form :inline="true" class="form-inline formBox">
                                    <el-form-item label="行政区划" >
                                        <el-cascader :key="parseInt(Math.random()*10000)" style="width: 160px" size="medium" v-model="region" :options="regions" :props="{ checkStrictly: true, expandTrigger: 'hover' }" clearable ></el-cascader>
                                    </el-form-item>
                                    <el-form-item label="项目名称">
                                        <el-input style="width: 180px" size="medium" v-model="xmmc" placeholder="请输入"></el-input>
                                    </el-form-item>
<!--                                    <el-form-item label="建设类别">-->
<!--                                        <el-select  @change="changeJslb" v-model="gllx" placeholder="请选择" style="width: 200px">-->
<!--                                            <el-option label="" value="">全部</el-option>-->
<!--                                            <el-option label="高速公路" value="高速公路">高速公路</el-option>-->
<!--                                            <el-option label="普通国道" value="普通国道">普通国道</el-option>-->
<!--                                            <el-option label="普通省道" value="普通省道">普通省道</el-option>-->
<!--                                        </el-select>-->
<!--                                    </el-form-item>-->
                                    <el-form-item label="项目性质">
                                        <el-cascader size="medium" style="width: 180px" v-model="checkedXmxz" :options="xmxzList" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>
                                        </el-cascader>
                                    </el-form-item>
                                    <el-form-item label="建设性质">
                                        <el-cascader size="medium" style="width: 180px" v-model="checkedJsxz" :options="jsxzList" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>
                                        </el-cascader>
                                    </el-form-item>
                                    <el-form-item label="项目功能" v-if="activeRoad === '普通省道'">
                                        <el-cascader size="medium" style="width: 180px" v-model="checkedXmgn" :options="xmgnList" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>
                                        </el-cascader>
                                    </el-form-item>
                                    <el-form-item  label="分类">
                                        <el-cascader size="medium" style="width: 180px" v-model="checkedLabel" :options="labelList" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>
                                        </el-cascader>
                                    </el-form-item>
                                    <el-button type="primary" style="margin-top:19px" size="medium" icon="el-icon-search" @click="currentPage = 1;search();">查询</el-button>
                                    <el-button type="primary" size="medium" plain @click="clearCondition();search();" icon="el-icon-delete">清空</el-button>
                                </el-form>
                                <!-- <el-form :inline="true" class="form-inline">
                                    <el-form-item>
                                        <el-cascader style="width: 220px" size="medium" v-model="region" :options="regions" :props="{ expandTrigger: 'hover' }" @change="handleChange"></el-cascader>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-input style="width: 260px" size="medium" v-model="xmmc" placeholder="项目名称"></el-input>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-button type="primary" size="medium" icon="el-icon-search" @click="currentPage = 1;search();">查询</el-button>
                                        <el-button type="primary" size="medium" plain @click="clearCondition();search();" icon="el-icon-delete"></el-button>
                                        <el-button type="primary" size="medium" @click="exportData"><i class="fa fa-upload"></i> 导出</el-button>
                                    </el-form-item>
                                    <el-form-item class="fright">
                                        <el-button type="primary" plain size="small" @click="activeIndex = !activeIndex">
                                            {{ activeIndex ? "收起筛选" : "展开筛选" }}
                                            <i :class="{ 'el-icon-arrow-up': activeIndex }"> </i>
                                            <i :class="{ 'el-icon-arrow-down': !activeIndex }"> </i>
                                        </el-button>
                                    </el-form-item>
                                    <div class="dl-item" v-show="activeIndex == 1">
                                        <dl>
                                            <dt>建设类别：</dt>
                                            <dd>
                                                <el-radio-group v-model="gllx" @change="handleChange">
                                                    <el-radio label="">全部</el-radio>
                                                    <el-radio label="高速公路">高速公路</el-radio>
                                                    <el-radio label="普通国道">普通国道</el-radio>
                                                    <el-radio label="普通省道">普通省道</el-radio>
                                                </el-radio-group>
                                            </dd>
                                        </dl>
                                        <dl>
                                            <dt>项目类型：</dt>
                                            <dd class="minwidth300">
                                                <el-checkbox :indeterminate="isIndeterminateXmlx" v-model="checkAllXmlx" @change="handleCheckAllXmlxChange">全选</el-checkbox>
                                                <el-checkbox-group v-model="checkedXmlx" @change="handleCheckedXmlxChange">
                                                    <el-checkbox v-for="xmlx in xmlxList" :label="xmlx" :key="xmlx" :disabled="chechfieldxmlx()">{{ xmlx }}</el-checkbox>
                                                </el-checkbox-group>
                                            </dd>
                                        </dl>
                                        <dl v-show="xmxzShow">
                                            <dt>项目性质：</dt>
                                            <dd style="height: auto">
                                                <el-checkbox :indeterminate="isIndeterminateXmxz" v-model="checkAllXmxz" @change="handleCheckAllXmxzChange">全选</el-checkbox>
                                                <el-checkbox-group v-model="checkedXmxz" @change="handleCheckedXmxzChange">
                                                    <el-checkbox v-for="xmxz in xmxzList" :label="xmxz" :key="xmxz" :disabled="checkfieldxmxz(xmxz)">{{ xmxz }}</el-checkbox>
                                                </el-checkbox-group>
                                            </dd>
                                        </dl>
                                        <dl v-show="jsxzShow">
                                            <dt>建设性质：</dt>
                                            <dd>
                                                <el-checkbox :indeterminate="isIndeterminateJsxz" v-model="checkAllJsxz" @change="handleCheckAllJsxzChange">全选</el-checkbox>
                                                <el-checkbox-group v-model="checkedJsxz" @change="handleCheckedJsxzChange">
                                                    <el-checkbox v-for="jsxz in jsxzList" :label="jsxz" :key="jsxz" :disabled="checkfieldjsxz()">{{ jsxz }}</el-checkbox>
                                                </el-checkbox-group>
                                            </dd>
                                        </dl>
                                        <dl v-show="xmgnShow">
                                            <dt>项目功能：</dt>
                                            <dd>
                                                <el-checkbox :indeterminate="isIndeterminateXmgn" v-model="checkAllXmgn" @change="handleCheckAllXmgnChange">全选</el-checkbox>
                                                <el-checkbox-group v-model="checkedXmgn" @change="handleCheckedXmgnChange">
                                                    <el-checkbox v-for="xmgn in xmgnList" :label="xmgn" :key="xmgn">{{ xmgn }}</el-checkbox>
                                                </el-checkbox-group>
                                            </dd>
                                        </dl>
                                    </div>
                                </el-form> -->
                            </div>
                        </div>
                    </el-col>
                </el-row>
                <el-row class="plr12">
                    <el-col :span="24">
                        <div class="box">
                            <div class="box-title">
                                <span class="fright">
                                    <el-button @click="exportData" type="primary" size="mini" round icon="el-icon-upload2">导出</el-button>
<!--                                    <el-button @click="drawer = true;getTotal();" type="primary" size="mini" round icon="el-icon-s-data">汇总</el-button>-->
<!--                                    <el-button @click="drawer1 = true;getRoadTotal();" type="primary" size="mini" round icon="el-icon-s-flag">公路现状</el-button>-->
                                </span>
                                <h3 class="tit">公路列表</h3>
                            </div>
                            <div class="box-content">
                                <div class="tj">
                                    <div class="total-item total-item-1">
                                        <img src="../../assets/icon/tj1.png" alt="">
                                        <div class="t">
                                            <h5>项目数量<small>（个）</small></h5>
                                            <b class="date-num">{{ xmsl }}</b>
                                        </div>
                                    </div>

                                    <div class="total-item total-item-2">
                                        <img src="../../assets/icon/tj2.png" alt="">
                                        <div class="t">
                                            <h5>建设规模-公路<small>（公里）</small></h5>
                                            <b class="date-num">{{ jsgm }}</b>
                                        </div>
                                    </div>

                                    <div class="total-item total-item-3">
                                        <img src="../../assets/icon/tj3.png" alt="">
                                        <div class="t">
                                            <h5>建设规模-桥隧<small>（延米）</small></h5>
                                            <b class="date-num">{{ jsqmqs }}</b>
                                        </div>
                                    </div>

                                    <div class="total-item total-item-4">
                                        <img src="../../assets/icon/tj4.png" alt="">
                                        <div class="t">
                                            <h5>总投资<small>（亿元）</small></h5>
                                            <b class="date-num">{{ ztz.toFixed(1) }}</b>
                                        </div>
                                    </div>

                                    <div class="total-item total-item-5">
                                        <img src="../../assets/icon/tj5.png" alt="">
                                        <div class="t">
                                            <h5>十四五投资<small>（亿元）</small></h5>
                                            <b class="date-num">{{ sswtz.toFixed(1) }}</b>
                                        </div>
                                    </div>
                                </div>
                                <el-tabs v-model="activeRoad" @tab-click="changeRoad">
                                    <el-tab-pane label="全部" name="全部"></el-tab-pane>
                                    <el-tab-pane label="高速公路" name="高速公路"></el-tab-pane>
                                    <el-tab-pane label="普通国道" name="普通国道"></el-tab-pane>
                                    <el-tab-pane label="普通省道" name="普通省道"></el-tab-pane>
                                </el-tabs>
                                <el-table @sort-change="changeSort" @cell-click="cellClick" ref="table" :data="tableData" size="small " :height="tableHeight" border style="width: 100%">
                                     <el-table-column fixed prop="index"  label="序号"  align="center" type="index" :width="50" :index="1+30*(currentPage-1)"></el-table-column>
                                    <el-table-column  fixed prop="XMMC" label="项目名称" show-overflow-tooltip :width="320" sortable></el-table-column>
<!--                                    <el-table-column fixed prop="SSDS" label="所在地市" :width="100" sortable show-overflow-tooltip></el-table-column>-->
<!--                                    <el-table-column fixed prop="SSX" label="所属县（市）" :width="150" sortable show-overflow-tooltip></el-table-column>-->
                                    <el-table-column prop="LXBH" label="路线编号" :width="150" sortable show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="XMXZ" label="项目性质" :width="150" sortable show-overflow-tooltip></el-table-column>
<!--                                    <el-table-column prop="JSXZ" label="建设性质" :width="150" sortable show-overflow-tooltip></el-table-column>-->
<!--                                    <el-table-column prop="XMLX" label="项目类型" :width="150" sortable show-overflow-tooltip></el-table-column>-->
<!--                                    <el-table-column prop="GLLX" label="建设类别" :width="150" sortable show-overflow-tooltip></el-table-column>-->
                                    <el-table-column prop="ZTZ" label="总投资" align="center" :width="150" sortable show-overflow-tooltip>
                                        <template slot-scope="scope">
                                            <div style="text-align: right">
<!--                                                {{scope.row.ZTZ ? thousandBitSeparator(scope.row.ZTZ.toFixed(0) ): ''}}-->
                                                {{scope.row.ZTZ }}
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="建设规模（公里）" align="center">
                                        <el-table-column prop="JSGMGS" label="高速" align="right" :width="100" sortable show-overflow-tooltip>
                                            <template slot-scope="scope" >
                                            {{scope.row.JSGMGS ? scope.row.JSGMGS.toFixed(3) : ''}}
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="JSGMYJ" label="一级" align="right" :width="100" sortable show-overflow-tooltip>
                                            <template slot-scope="scope">
                                            {{scope.row.JSGMYJ ? scope.row.JSGMYJ.toFixed(3) : ''}}
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="JSGMEJ" label="二级" align="right" :width="100" sortable show-overflow-tooltip>
                                            <template slot-scope="scope">
                                            {{scope.row.JSGMEJ ? scope.row.JSGMEJ.toFixed(3) : ''}}
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="JSGMSJ" label="三级" align="right" :width="100" sortable show-overflow-tooltip>
                                            <template slot-scope="scope">
                                            {{scope.row.JSGMSJ ? scope.row.JSGMSJ.toFixed(3) : ''}}
                                            </template>
                                        </el-table-column>
                                    </el-table-column>
                                    <el-table-column prop="JSGMQS" label="桥隧（延米）" align="right" :width="120" sortable show-overflow-tooltip>
                                        <template slot-scope="scope">
                                            {{scope.row.JSGMQS ? scope.row.JSGMQS.toFixed(2) : ''}}
                                        </template>
                                    </el-table-column>
<!--                                    <el-table-column  label="投资" align="center" sortable show-overflow-tooltip>-->
<!--                                        <el-table-column prop="ZYCGS" label="中央车购税" :width="150" sortable show-overflow-tooltip>-->
<!--                                            <template slot-scope="scope">-->
<!--                                                <div style="text-align: right">-->
<!--                                                    {{scope.row.ZYCGS ? thousandBitSeparator(scope.row.ZYCGS.toFixed(0) ): ''}}-->
<!--                                                </div>-->
<!--                                            </template>-->
<!--                                        </el-table-column>-->
<!--                                        <el-table-column prop="SAWZTJ" label="“十三五”投资（亿元）" :width="170" sortable show-overflow-tooltip>-->
<!--                                            <template slot-scope="scope">-->
<!--                                                <div style="text-align: right">-->
<!--                                                    {{scope.row.SAWZTJ ? thousandBitSeparator(scope.row.SAWZTJ.toFixed(0) ): ''}}-->
<!--                                                </div>-->
<!--                                            </template>-->
<!--                                        </el-table-column>-->
<!--                                        <el-table-column prop="SAWZYCGS" label="“十三五”中央车购税（亿元）" :width="210" sortable show-overflow-tooltip></el-table-column>-->
<!--                                        <el-table-column prop="SSWZTZ" label="“十四五”投资" :width="160" sortable show-overflow-tooltip></el-table-column>-->
<!--                                        <el-table-column prop="SSWZYCGS" label="“十四五”中央车购税" :width="210" sortable show-overflow-tooltip></el-table-column>-->
<!--                                        <el-table-column prop="SSWSJZJ" label="“十四五”省级资金" :width="170" sortable show-overflow-tooltip></el-table-column>-->
<!--                                        <el-table-column prop="SSWSXZCTZ" label="“十四五”市县征拆投资" :width="170" sortable show-overflow-tooltip></el-table-column>-->
<!--                                        <el-table-column prop="SWWZTZ" label="“十五五”投资" :width="160" sortable show-overflow-tooltip></el-table-column>-->
<!--                                        <el-table-column prop="SWWZYCGS" label="“十五五”中央车购税" :width="170" sortable show-overflow-tooltip></el-table-column>-->
<!--                                    </el-table-column>-->

<!--                                    <el-table-column prop="KGN" label="开工年" :width="150" sortable show-overflow-tooltip></el-table-column>-->
<!--                                    <el-table-column prop="WGN" label="完工年" :width="150" sortable show-overflow-tooltip></el-table-column>-->
<!--                                    <el-table-column prop="SFYDSXW" label="是否地市行文" :width="160" sortable show-overflow-tooltip></el-table-column>-->
<!--                                    <el-table-column prop="XMLY" label="项目来源" :width="160" sortable show-overflow-tooltip></el-table-column>-->
<!--                                    <el-table-column prop="QQGZJZ" label="前期工作进展" :width="160" sortable show-overflow-tooltip></el-table-column>-->
<!--                                    <el-table-column prop="XMGN" label="项目功能" :width="160" sortable show-overflow-tooltip></el-table-column>-->
<!--                                    <el-table-column prop="BZ" label="备注" show-overflow-tooltip></el-table-column>-->
                                    <el-table-column prop="TagList" label="标签" :width="275" align="center" show-overflow-tooltip>
                                        <template slot-scope="scope">
                                            <div style="display: flex;align-items: center;flex-wrap: wrap">
                                                <div class="bigLabels" :style="{background: item.BQYS}" v-for="(item,index) in scope.row.TagList" :key="index">
                                                    {{item.BQWZ}}
                                                </div>
                                            </div>
                                        </template>

                                    </el-table-column>
                                    <el-table-column fixed="right" prop="" label="操作" :width="260">
                                        <template slot-scope="scope">
                                            <div style="display: flex;align-items: center">
                                                <span class="newicon iconsyes" :class="{iconsyes1:!scope.row.isShowPosi}" @click="showMaps(scope.row)"><i class="el-icon-location icons"></i>定位</span>
                                                <span class="newicon iconsyes" @click="openDetails(scope.row)"><i class="el-icon-info icons"></i>详情</span>
                                                <span class="newicon iconsyes" :class="{iconsyes1:scope.row.SFQQXM =='否'}" @click="openLct(scope.row)"><i class="el-icon-share icons"></i>前期流程</span>
                                                <span @click="openLabel(scope.row)" class="newicon  iconsyes" style="display: flex;align-items: center;color: #409eff"><img style="margin-right: 0;width: 17px;height:18px;transform: rotate(-90deg)" src="../../assets/images/interactive/label.png"  alt="">标签</span>
                                            </div>
                                            <!-- <i class="el-icon-location icons iconsyes" @click="showMaps(scope.row)"></i>
                                            <i class="el-icon-postcard icons iconsyes" @click="openDetails(scope.row)"></i>
                                            <i class="el-icon-share icons iconsyes" @click="openLct(scope.row)"></i>
                                            <i class="el-icon-thumb icons iconsyes" style="margin-right: 0;color:#f00" @click="openZp(scope.row)"></i> -->
                                        </template>
                                    </el-table-column>
                                </el-table>
                                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[30, 50, 100, 400]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </el-main>
        </el-container>
        <el-drawer title="汇总" :visible.sync="drawer" :direction="direction" size="910px">
            <Hz v-if="drawer" :totalXmxz="totalXmxz" :totalJsxz="totalJsxz" :totalXzqh="totalXzqh" />
        </el-drawer>
        <el-drawer title="公路现状" :visible.sync="drawer1" :direction="direction" size="600px">
            <Glxz :total_glxz_xzdj="total_glxz_xzdj" :total_glxz_jsdj="total_glxz_jsdj" :total_glxz_gsdjgl="total_glxz_gsdjgl" :total_glxz_xycdjgl="total_glxz_xycdjgl" />
        </el-drawer>

        <el-dialog :title="dialogDetails.XMMC" :visible.sync="dialogTableVisible" class="dialog-md">
            <div class="mb15">
                <el-button type="primary" v-if="btnviewshow" @click="handleChange1" size="small">完善信息</el-button>
                <el-button type="primary" v-if="btneditshow" @click="handleChange2(), submit()" size="small">保存</el-button>
                <el-button v-if="btneditshow" size="small" @click="handleChange2">取消</el-button>
            </div>
            <div class="view-card-info" v-if="viewshow">
                <div class="mycard-lg">
                    <table class="table">
                        <tr>
                            <th width="150">项目名称</th>
                            <td colspan="3">{{ dialogDetails.XMMC }}</td>
                            <th width="150">路线编号</th>
                            <td>{{ dialogDetails.LXBH || "-" }}</td>
                        </tr>
                        <tr>
                            <th>所在地市</th>
                            <td>{{ dialogDetails.SSDS || "-" }}</td>
                            <th width="190">所属县（市）</th>
                            <td>{{ dialogDetails.SSX || "-" }}</td>
                            <th>项目性质</th>
                            <td>{{ dialogDetails.XMXZ || "-" }}</td>
                        </tr>
                        <tr>
                            <th>建设性质</th>
                            <td>{{ dialogDetails.JSXZ || "-" }}</td>
                            <th>项目类型</th>
                            <td>{{ dialogDetails.XMLX || "-" }}</td>
                            <th>建设类别</th>
                            <td>{{ dialogDetails.GLLX || "-" }}</td>
                        </tr>
                        <tr>
                            <th>开工年</th>
                            <td>{{ dialogDetails.KGN || "-" }}</td>
                            <th>完工年</th>
                            <td>{{ dialogDetails.WGN || "-" }}</td>
                            <th>建设规模高速（公里）</th>
                            <td>{{ dialogDetails.JSGMGS || "-" }}</td>
                        </tr>
                        <tr>
                            <th>建设规模一级（公里）</th>
                            <td>{{ dialogDetails.JSGMYJ || "-" }}</td>
                            <th>建设规模二级（公里）</th>
                            <td>{{ dialogDetails.JSGMEJ || "-" }}</td>
                            <th>建设规模三级（公里）</th>
                            <td>{{ dialogDetails.JSGMSJ || "-" }}</td>
                        </tr>
                        <tr>
                            <th>建设规模桥隧（数量）</th>
                            <td>{{ dialogDetails.JSGMQS || "-" }}</td>
                            <th>总投资</th>
                            <td>{{ dialogDetails.ZTZ || "-" }}</td>
                            <th>中央车购税</th>
                            <td>{{ dialogDetails.ZYCGS || "-" }}</td>
                        </tr>
                        <tr>
                            <th>“十三五”投资（亿元）</th>
                            <td>{{ dialogDetails.SAWZTJ || "-" }}</td>
                            <th>“十三五”中央车购税（亿元）</th>
                            <td>{{ dialogDetails.SAWZYCGS || "-" }}</td>
                            <th>“十四五”投资</th>
                            <td>{{ dialogDetails.SSWZTZ || "-" }}</td>
                        </tr>
                        <tr>
                            <th>“十四五”中央车购税</th>
                            <td>{{ dialogDetails.SSWZYCGS || "-" }}</td>
                            <th>“十四五”省级资金</th>
                            <td>{{ dialogDetails.SSWSJZJ || "-" }}</td>
                            <th>“十四五”市县征拆投资</th>
                            <td>{{ dialogDetails.SSWSXZCTZ || "-" }}</td>
                        </tr>
                        <tr>
                            <th>“十五五”投资</th>
                            <td>{{ dialogDetails.SWWZTZ || "-" }}</td>
                            <th>“十五五”中央车购税</th>
                            <td>{{ dialogDetails.SWWZYCGS || "-" }}</td>
                            <th>是否地市行文</th>
                            <td>{{ dialogDetails.SFYDSXW || "-" }}</td>
                        </tr>
                        <tr>
                            <th>项目来源</th>
                            <td>{{ dialogDetails.XMLY || "-" }}</td>
                            <th>前期工作进展</th>
                            <td>{{ dialogDetails.QQGZJZ || "-" }}</td>
                            <th>备注</th>
                            <td colspan="3">{{ dialogDetails.BZ || "-" }}</td>
                        </tr>
                        <tr></tr>
                    </table>
                </div>
            </div>
            <div class="edit-card-info" v-if="editshow">
                <el-alert title="填报完信息记得点击保存按钮" type="error" class="mb15"></el-alert>
                <el-form ref="formdata" :model="formdata">
                    <div class="mycard-lg">
                        <table class="table">
                            <tr>
                                <th width="150">项目名称</th>
                                <td colspan="3">
                                    <el-input v-model="formdata.XMMC" ></el-input>
                                </td>
                                <th width="150">路线编号</th>
                                <td>
                                    <el-input v-model="formdata.LXBH"></el-input>
                                </td>
                            </tr>
                            <tr>
                                <th>所在地市</th>
                                <td>
                                    <el-input v-model="formdata.SSDS" ></el-input>
                                </td>
                                <th width="190">所属县（市）</th>
                                <td>
                                    <el-input v-model="formdata.SSX" ></el-input>
                                </td>
                                <th>项目性质</th>
                                <td>
                                    <el-input v-model="formdata.XMXZ" disabled></el-input>
                                </td>
                            </tr>
                            <tr>
                                <th>建设性质</th>
                                <td>
                                    <el-select v-model="formdata.JSXZ" placeholder="请选择" :disabled="formdata.GLLX == '高速公路'">
                                        <el-option label="新建" value="新建"></el-option>
                                        <el-option label="升级改造" value="升级改造"></el-option>
                                        <el-option label="原级改造" value="原级改造"></el-option>
                                        <el-option label="路面改造" value="路面改造"></el-option>
                                        <el-option label="改扩建" value="改扩建"></el-option>
                                    </el-select>
                                </td>
                                <th>项目类型</th>
                                <td>
                                    <el-input v-model="formdata.XMLX"></el-input>
                                </td>
                                <th>建设类别</th>
                                <td>
                                    <el-input v-model="formdata.GLLX" disabled></el-input>
                                </td>
                            </tr>
                            <tr>
                                <th>开工年</th>
                                <td>
                                    <el-input v-model="formdata.KGN"></el-input>
                                </td>
                                <th>完工年</th>
                                <td>
                                    <el-input v-model="formdata.WGN"></el-input>
                                </td>
                                <th>建设规模高速（公里）</th>
                                <td>
                                    <el-input v-model="formdata.JSGMGS"></el-input>
                                </td>
                            </tr>
                            <tr>
                                <th>建设规模一级（公里）</th>
                                <td>
                                    <el-input v-model="formdata.JSGMYJ"></el-input>
                                </td>
                                <th>建设规模二级（公里）</th>
                                <td>
                                    <el-input v-model="formdata.JSGMEJ"></el-input>
                                </td>
                                <th>建设规模三级（公里）</th>
                                <td>
                                    <el-input v-model="formdata.JSGMSJ"></el-input>
                                </td>
                            </tr>
                            <tr>
                                <th>建设规模桥隧（数量）</th>
                                <td>
                                    <el-input v-model="formdata.JSGMQS"></el-input>
                                </td>
                                <th>总投资</th>
                                <td>
                                    <el-input v-model="formdata.ZTZ"></el-input>
                                </td>
                                <th>中央车购税</th>
                                <td>
                                    <el-input v-model="formdata.ZYCGS"></el-input>
                                </td>
                            </tr>
                            <tr>
                                <th>“十三五”投资（亿元）</th>
                                <td>
                                    <el-input v-model="formdata.SAWZTJ"></el-input>
                                </td>
                                <th>“十三五”中央车购税（亿元）</th>
                                <td>
                                    <el-input v-model="formdata.SAWZYCGS"></el-input>
                                </td>
                                <th>“十四五”投资</th>
                                <td>
                                    <el-input v-model="formdata.SSWZTZ"></el-input>
                                </td>
                            </tr>
                            <tr>
                                <th>“十四五”中央车购税</th>
                                <td>
                                    <el-input v-model="formdata.SSWZYCGS"></el-input>
                                </td>
                                <th>“十四五”省级资金</th>
                                <td>
                                    <el-input v-model="formdata.SSWSJZJ"></el-input>
                                </td>
                                <th>“十四五”市县征拆投资</th>
                                <td>
                                    <el-input v-model="formdata.SSWSXZCTZ"></el-input>
                                </td>
                            </tr>
                            <tr>
                                <th>“十五五”投资</th>
                                <td>
                                    <el-input v-model="formdata.SWWZTZ"></el-input>
                                </td>
                                <th>“十五五”中央车购税</th>
                                <td>
                                    <el-input v-model="formdata.SWWZYCGS"></el-input>
                                </td>
                                <th>是否地市行文</th>
                                <td>
                                    <el-input v-model="formdata.SFYDSXW"></el-input>
                                </td>
                            </tr>
                            <tr>
                                <th>项目来源</th>
                                <td>
                                    <el-input v-model="formdata.XMLY"></el-input>
                                </td>
                                <th>前期工作进展</th>
                                <td>
                                    <el-input v-model="formdata.QQGZJZ"></el-input>
                                </td>
                                <th>备注</th>
                                <td colspan="3">
                                    <el-input v-model="formdata.BZ"></el-input>
                                </td>
                            </tr>
                            <tr></tr>
                        </table>
                    </div>
                </el-form>
            </div>

        </el-dialog>
        <el-dialog :title="dialogTableVisibleData.XMMC" :visible.sync="dialogTableVisibleMap"  class="mapdialog mapdialog1" style="margin-top: 0 !important;">
            <Map v-if="dialogTableVisibleMap" :dialogData="dialogTableVisibleData" />
        </el-dialog>
        <!--        <el-drawer :title="lctname" :visible.sync="lct" :direction="'rtl'" size="100%">-->
        <!--            <LctGsgl v-if="lct && rowlct == '高速公路'" />-->
        <!--            <LctGsd v-if="lct && rowlct != '高速公路'" />-->
        <!--        </el-drawer>-->
        <el-drawer onselectstart="return false;" :title="lctname" size="100%" style="overflow:auto;padding:0" :append-to-body="true" :visible.sync="lct">
            <FlowXmk :id="SSWXMID"></FlowXmk>
        </el-drawer>
        <el-drawer :visible.sync="assign" :title="XMMC+'-建设单位列表'" size="80%">
            <el-form :inline="true" :model="formInline" class="demo-form-inline" style="margin-top: 42px">
                <el-form-item label="单位名称">
                    <el-input style="width: 300px" v-model="formInline.dwmc" placeholder="请输入单位名称"></el-input>
                </el-form-item>
                <el-form-item label="项目负责人">
                    <el-input v-model="formInline.xmzrr" placeholder="请输入项目负责人"></el-input>
                </el-form-item>
                <el-form-item label="项目联系人">
                    <el-input v-model="formInline.xmlxr" placeholder="请输入项目联系人"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="getCompanyList">查询</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="bindCompany">指派</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="success" @click="addCompany">新增</el-button>
                </el-form-item>
            </el-form>
            <template>
                <el-table :row-class-name="tableRowClassName" max-height="550" ref="multipleTable" :data="companyData" style="width: 100%" @selection-change="selectCompany">
                    <el-table-column type="selection" width="55" :selectable="checkSelectable" />
                    <el-table-column :filter-multiple="false" fixed prop="DWMC" label="单位名称" show-overflow-tooltip></el-table-column>
                    <el-table-column fixed prop="XMZRR" label="项目负责人" show-overflow-tooltip></el-table-column>
                    <el-table-column fixed width="150px" prop="XMZRRLXDH" label="负责人联系电话" show-overflow-tooltip></el-table-column>
                    <el-table-column fixed prop="XMLXR" label="项目联系人" show-overflow-tooltip></el-table-column>
                    <el-table-column fixed prop="XMLXRLXDH" label="联系人电话" show-overflow-tooltip></el-table-column>
                    <el-table-column fixed label="操作" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <el-button type="text" size="small" @click="edit(scope.row)">编辑</el-button>
                            <el-popconfirm title="是否删除此条信息?" @confirm="deleted(scope.row)">
                                <template #reference>
                                    <el-button style="margin-left: 12px" type="text" size="small" color="#d6000f">删除</el-button>
                                </template>
                            </el-popconfirm>
                        </template>
                    </el-table-column>
                </el-table>
                <!--                <el-pagination background @size-change="handleSizeChange1" @current-change="handleCurrentChange1" :current-page="currentPage1" :page-sizes="[30, 50, 100, 400]" :page-size="pageSize1" layout="total, sizes, prev, pager, next, jumper" :total="total1"></el-pagination>-->
            </template>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="assign = false">取消</el-button>
                </span>
            </template>
        </el-drawer>
        <el-drawer :visible.sync="showEdit" :title="title" width="40%">
            <template>
                <el-form label-width="120px" ref="editForm" :model="editModel" class="demo-ruleForm">
                    <el-form-item label="建设单位名称" prop="dwmc" style="margin-top: 45px">
                        <el-input v-model="editModel.DWMC" placeholder="请输入单位名称"></el-input>
                    </el-form-item>
                    <el-form-item label="项目负责人" prop="xmzrr">
                        <el-input v-model="editModel.XMZRR" placeholder="请输入项目负责人"></el-input>
                    </el-form-item>
                    <el-form-item label="负责人联系电话" prop="xmzrrlxdh">
                        <el-input v-model="editModel.XMZRRLXDH" placeholder="请输入项目负责人"></el-input>
                    </el-form-item>
                    <el-form-item label="项目联系人" prop="xmlxr">
                        <el-input v-model="editModel.XMLXR" placeholder="请输入项目联系人"></el-input>
                    </el-form-item>
                    <el-form-item label="联系人电话" prop="xmlxrlxdh">
                        <el-input v-model="editModel.XMLXRLXDH" placeholder="请输入项目联系人"></el-input>
                    </el-form-item>
                </el-form>
            </template>
            <!--            <template #footer>-->
            <span class="dialog-footer" style="justify-content: flex-end;display: flex;align-items:center">
                <el-button type="primary" @click="saveCompany">保存</el-button>
                <el-button @click="showEdit = false">取消</el-button>
            </span>
            <!--            </template>-->
        </el-drawer>
        <!--        标签列表-->
        <el-drawer :visible.sync="showLabel" title="项目标签" size="479px" :inline="true">
            <el-main>
                <el-button style="float: right;margin-bottom: 10px" type="success" icon="el-icon-plus" @click="addLabel">添加</el-button>
                <el-table border ref="multipleTables" :data="labelList" style="width: 100%" @selection-change="selectLabel" :max-height=tagHeight>
                    <el-table-column type="selection" width="47" />
                    <el-table-column width="100px" prop="color" label="标签颜色" align="center">
                        <template slot-scope="scope">
                            <div class="labels" :style="{background:scope.row.BQYS}"></div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="BQWZ" label="标签文字"  align="center" show-overflow-tooltip></el-table-column>
                    <el-table-column label="操作" show-overflow-tooltip align="center">
                        <template slot-scope="scope">
                            <i class="edit el-icon-edit" @click="chooseColor(scope.row)"></i>
                            <el-popconfirm title="是否删除此条信息?" @confirm="deletedLabel(scope.row)">
                                <template #reference>
                                    <el-button style="margin-left: 12px" type="text" size="small" color="#d6000f"><i class="el-icon-delete delete"></i></el-button>
                                </template>
                            </el-popconfirm>
                        </template>
                    </el-table-column>
                </el-table>
                <span class="dialog-footer">
                    <el-button type="primary" @click="saveLable">保存</el-button>
                    <el-button @click="showLabel = false">取消</el-button>
                </span>
                <!--            </template>-->
            </el-main>
        </el-drawer>
        <!--        标签新增/修改弹窗-->
        <el-drawer :append-to-body="true" :visible.sync="showColor" :title="labelTitle" class="form-inline" size="479px" :inline="true">
            <el-form :inline="true" :model="colorObj" class="demo-form-inline" style="margin-top: 42px;display:flex">
                <div class="search">
                    <div class="flex">
                        <div class="label">标签文字</div>
                        <el-form-item>
                            <el-input style="width: 312px" placeholder="请输入标签文字" v-model="colorObj.BQWZ"></el-input>
                        </el-form-item>
                    </div>
                    <div class="flex">
                        <div class="label">标签颜色</div>
                        <el-form-item>
                            <el-color-picker @change="changeColor" v-model="colorObj.BQYS">
                                <!--                                <div class="labels" :style="{background:colorObj.color}"></div>-->
                            </el-color-picker>
                        </el-form-item>
                    </div>
                </div>
            </el-form>
            <span class="dialog-footer">
                <el-button type="primary" @click="saveColor">保存</el-button>
                <el-button @click="showColor = false">取消</el-button>
            </span>
        </el-drawer>
    </div>
</template>
<script>
import Hz from "@/components/Query/Road/Hz.vue"; //汇总
import Glxz from "@/components/Query/Road/Glxz.vue"; //公路现状
import { exportData } from "../../js/util/export.js"; //导出表格
// import Map from "../Map/Index.vue";
import Map from "../AMap/Box.vue";
import FlowXmk from "../../components/Query/Road/XmkFlow";
// import LctGsgl from "../Query/LctGsgl.vue";
// import LctGsd from "../Query/LctGsd.vue";

export default {
    name: "Home",
    components: {
        FlowXmk,
        Hz,
        Glxz,
        Map,
    },
    data() {
        return {
            noLine:['国道绥芬河至满洲里公路尚志阿城界至平山镇段','绥满高速公路哈尔滨至阿城段（谋划）','绥满高速哈尔滨至大庆段（谋划）','北漠高速五大连池至嫩江段','北漠高速嫩江至加格达奇段（谋划）',
                '宝清支线机场连接线','逊克通用机场连接线','省道哈尔滨至亚布力公路尚志镇至一面坡镇段','省道哈尔滨至大安公路世贸大道至哈肇界段',
                '省道名山至兴凯湖公路291一分场19队入点至291一分场30队出点','省道同江至汪清公路集贤镇至福利镇段','省道四季屯至鹤岗公路小桦林子村至小队段',
                '省道方正至珲春公路牛心山经营所至新合桥段','省道阿城至扶余公路拉林河大桥','省道连崟至兴华公路新林至兴华段','省道逊克至孙吴公路逊克口岸至逊克（靠山村）段',
            '国道北京至抚远公路哈尔滨四环路至呼兰段','国道鹤岗至大连公路小五站镇至勃利鸡东界段','国道同江至哈尔滨公路二龙山镇至大榆树镇段',
            '国道同江至哈尔滨公路友谊至集贤段','国道同江至哈尔滨公路佳木斯依兰界至依兰方正界段','国道嘉荫至临江公路南岔至铁力段','国道绥芬河至满洲里公路尚志过境段','国道绥芬河至满洲里公路尚志阿城界至平山镇段','国道丹东至阿勒泰公路八面通镇至鸡穆界段'
            ,'国道丹东至阿勒泰公路鸡穆界至鸡西段','国道丹东至阿勒泰公路鸡东密山界至新华村段','国道丹东至阿勒泰公路卧牛湖至黑呼界段','国道三莫公路雪乡至滑雪场段','国道三莫公路滑雪场至亚布力段','国道集贤至当壁公路宝山区至宝清段'],
            activeRoad: '全部',
            labelTitle: "新增标签",
            colorObj: {},
            SSWXMID: "",
            runArr: [],
            searchAll: [],
            XMMC: "",
            newAdd: true,
            editModel: {},
            multipleSelection: [],
            multipleSelections: [],
            XMID: "", //项目名称
            XMLX: "",
            showEdit: false,
            title: "新增建设单位",
            companyPage: false,
            formInline: {},
            companyData: [],
            searchContent: {},
            assign: false,
            formdata: {},
            viewshow: true,
            editshow: false,
            btnviewshow: true,
            btneditshow: false,
            lct: false,
            lctname: "",
            dialogTableVisibleMap: false,
            dialogTableVisibleData: {},
            activecollapse: "1",
            dialogTableVisible: false,
            dialogDetails: {},
            tableHeight: 0, //表格高度
            activeIndex: true, //显隐高级搜索
            labelList:[],
            tagHeight: document.body.offsetHeight - 185, //表格高度
            /**查询条件 begin*/
            xmmc: "", //项目名称
            xmnd: "", //项目年度
            gllx: "", //建设类别
            checkedXmxz: [], //项目性质
            checkedXmgn: [], //项目性质
            checkedJsxz: [], //建设性质
            checkedXmlx: [], //项目类型
            region: [""], //已选中行政区划
            qqgzjz: [], //前期工作进展
            /**查询条件 end*/
            xmxzShow: true, //项目性质查询条件显隐
            xmgnShow: true, //项目性质查询条件显隐
            jsxzShow: true, //建设性质查询条件显隐
            xmlxShow: true, //项目类型查询条件显隐
            checkList: [],
            checkAllXmxz: false,
            checkAllXmgn: false,
            checkAllJsxz: false,
            checkAllXmlx: false,
            xmxzList: [
                {
                    value: "“十三五”续建",
                    label: "“十三五”续建",
                    disabled: false,
                },
                {
                    value: "“十四五”谋划",
                    label: "“十四五”谋划",
                    disabled: false,
                },
                {
                    value: "“十四五”新开工",
                    label: "“十四五”新开工",
                    disabled: false,
                },
            ],
            xmgnList: [
                { value: "出省通道", label: "出省通道", disabled: false },
                {
                    value: "区域内连通农林场、乡镇、景区",
                    label: "区域内连通农林场、乡镇、景区",
                    disabled: false,
                },
                { value: "城市过境段", label: "城市过境段", disabled: false },
                { value: "瓶颈路段", label: "瓶颈路段", disabled: false },
                { value: "砂石路", label: "砂石路", disabled: false },
                { value: "跨区域通道", label: "跨区域通道", disabled: false },
                {
                    value: "重要节点连接国省干线",
                    label: "重要节点连接国省干线",
                    disabled: false,
                },
            ],
            jsxzList: [
                { value: "新建", label: "新建", disabled: false },
                { value: "升级改造", label: "升级改造", disabled: false },
                { value: "原级改造", label: "原级改造", disabled: false },
                { value: "路面改造", label: "路面改造", disabled: false },
                { value: "改扩建", label: "改扩建", disabled: false },
            ],
            chek:[],
            checkedLabel: [], //标签分类
            xmlxList: ["正选项目", "备选项目"],
            currentPage: 1, //当前页
            currentPage1: 1, //当前页
            pageSize: 30, //每页记录数
            pageSize1: 3000, //每页记录数
            isIndeterminateXmxz: true,
            isIndeterminateXmgn: true,
            isIndeterminateJsxz: true,
            isIndeterminateXmlx: true,
            drawer: false,
            drawer1: false,
            direction: "rtl",
            props: { multiple: true },
            rowlct: "",
            regions: [
                {
                    value: "",
                    label: "黑龙江省",
                },
            ],
            singleData: {},
            tableData: [], //请求列表
            total: 0, //请求记录数
            total1: 0, //请求记录数
            xmsl: 0,
            jsgm: 0,
            jsqmqs: 0,
            ztz: 0,
            sswtz: 0,
            totalXmxz: [], //汇总按项目性质
            totalJsxz: [], //汇总按建设性质
            totalXzqh: [], //汇总按行政区划

            total_glxz_xzdj: [], //公路现状行政等级
            total_glxz_jsdj: [], //公路现状技术等级
            total_glxz_gsdjgl: [], //公路等级按国省道
            total_glxz_xycdjgl: [], //公路等级按县乡村道
            sortData: {
                field: "XH",
                sort: "asc",
            },
            showLabel: false,
            saveTag:[],
            showCard: false, //卡片
            showColor: false, //颜色
        };
    },
    mounted() {
        var that = this;
        let height = this.$refs.init.offsetHeight;
        setTimeout(() => {
            that.tableHeight = document.body.offsetHeight - height - 255;
        }, 100);
        this.getRegion();
        this.search();
        this.getTagList();
    },
    methods: {
        thousandBitSeparator(num) {
            var reg=/\d{1,3}(?=(\d{3})+$)/g;
            return (num + '').replace(reg, '$&,');
        },
        changeRoad(tab, event){
            this.currentPage = 1
            if(tab.label == '全部'){
                this.gllx = ''
            }else{
                this.gllx = tab.label
            }
            this.search()
        },
        cellClick(row, column, cell, event){
            if (column.label != '操作'){
                this.openDetails(row)
            }
        },
        // changeJslb(val){
        //     console.log(val)
        //     switch (val) {
        //         case '高速公路':
        //             this.xmxzList = [
        //                 {
        //                     value: "“十三五”续建项目",
        //                     label: "“十三五”续建项目",
        //                     disabled: false,
        //                 },
        //                 {
        //                     value: "2021年新开工项目",
        //                     label: "2021年新开工项目",
        //                     disabled: false,
        //                 },
        //                 {
        //                     value: "“十四五”谋划项目",
        //                     label: "“十四五”谋划项目",
        //                     disabled: false,
        //                 },
        //                 {
        //                     value: "“十四五”其他项目",
        //                     label: "“十四五”其他项目",
        //                     disabled: false,
        //                 },
        //             ]
        //             break;
        //         case '普通国道':
        //             this.xmxzList = [
        //                 {
        //                     value: "“十三五”续建项目",
        //                     label: "“十三五”续建项目",
        //                     disabled: false,
        //                 },
        //                 {
        //                     value: "“十四五”新开工项目",
        //                     label: "“十四五”新开工项目",
        //                     disabled: false,
        //                 },
        //                 {
        //                     value: "谋划项目",
        //                     label: "谋划项目",
        //                     disabled: false,
        //                 },
        //             ]
        //             break;
        //         case '普通省道':
        //             this.xmxzList = [
        //                 {
        //                     value: "“十三五”续建项目",
        //                     label: "“十三五”续建项目",
        //                     disabled: false,
        //                 },
        //                 {
        //                     value: "“十四五”新开工项目",
        //                     label: "“十四五”新开工项目",
        //                     disabled: false,
        //                 },
        //                 {
        //                     value: "“十四五”备选项目",
        //                     label: "“十四五”备选项目",
        //                     disabled: false,
        //                 },
        //             ]
        //             break;
        //         case '':
        //             this.xmxzList = [
        //                 {
        //                     value: "“十三五”续建项目",
        //                     label: "“十三五”续建项目",
        //                     disabled: false,
        //                 },
        //                 {
        //                     value: "“十四五”谋划项目",
        //                     label: "“十四五”谋划项目",
        //                     disabled: false,
        //                 },
        //                 {
        //                     value: "“十四五”新开工项目",
        //                     label: "“十四五”新开工项目",
        //                     disabled: false,
        //                 },
        //                 {
        //                     value: "谋划项目",
        //                     label: "谋划项目",
        //                     disabled: false,
        //                 },
        //                 {
        //                     value: "2021年新开工项目",
        //                     label: "2021年新开工项目",
        //                     disabled: false,
        //                 },
        //                 {
        //                     value: "“十四五”其他项目",
        //                     label: "“十四五”其他项目",
        //                     disabled: false,
        //                 },
        //             ]
        //             break;
        //     }
        // },
        getTagList(){
            var that = this
            this.http.post('/api/Land_road_tag_base/GetPageData',{page:1,rows:1000,}).then(res=>{
                console.log(res)
                this.labelList = res.rows
                this.labelList.map((item,index)=>{
                    item.label = item.BQWZ
                    item.value = item.ID
                    item.disabled = false
                })
                that.$nextTick(() => {
                    res.rows.forEach((item, index) => {
                        // if (!item.canChoose) {
                        //     // 默认被选中且禁用
                        //     this.$refs.multipleTable.toggleRowSelection(item, true);  // 如果只需要禁用 不需要这行
                        // }
                        //  selectReady 是默认要选中的数据
                        if (that.saveTag.length > 0) {
                            that.saveTag.forEach((obj, key) => {
                                if (item.ID == obj.TagId) { // 如果匹配上  代表当前角色下的用户  高亮选中
                                    console.log('22222222222')
                                    that.$refs.multipleTables.toggleRowSelection(item);
                                }
                            })
                        }
                    })
                    // this.chek = rows.TagList

                })

            })
        },
        addLabel() {
            this.colorObj = {};
            this.labelTitle = "新增标签";
            this.editColorStatus = true
            this.showColor = true;
        },
        saveColor() {
            if(this.colorObj.BQWZ == '' || this.colorObj.BQWZ == undefined){
                this.$message.error('请输入标签文字')
                return false
            }
            if(this.colorObj.BQYS == '' || this.colorObj.BQYS == undefined){
                this.$message.error('请选择颜色')
                return false
            }
            console.log(this.ID)
            var params = {
                id:'00000000-0000-0000-0000-000000000000',
                bqwz:this.colorObj.BQWZ,
                bqys:this.colorObj.BQYS
            }
            if(this.editColorStatus){
                params.id =  '00000000-0000-0000-0000-000000000000'
            }else{
                params.id =  this.colorObj.ID
            }
            if(this.editColorStatus){
                this.http.post("/api/Land_road_tag_base/AddLandRoadTagBase", params).then((res) => {
                    if(res.status){
                        this.$message.success('标签添加成功')
                        this.showColor = false
                        this.getTagList()
                        console.log( this.labelList)
                        // this.showLabel = false
                        this.search()
                    }else{
                        this.$message.error(res.message)
                    }
                })
            }else{
                this.http.post("/api/Land_road_tag_base/UpdateLandRoadTagBase", params).then((res) => {
                    if(res.status){
                        this.$message.success('保存成功')
                        this.showColor = false
                        this.getTagList()
                        // this.showLabel = false
                        this.search()
                    }else{
                        this.$message.error(res.message)
                    }
                })
            }
            console.log("保存颜色");
        },
        changeColor(val) {
            console.log(val);
        },
        chooseColor(row) {
            console.log(row);
            this.labelTitle = "编辑标签";
            this.colorObj = JSON.parse(JSON.stringify(row));
            this.showColor = true;
        },
        openLabel(rows) {
            var that = this
            console.log(rows)
            this.ID = rows.ID
            console.log(this.SSWGHXMID)
            this.showLabel = true;
            this.saveTag = rows.TagList
            this.editColorStatus = false
            this.getTagList()
            if(!rows.ID){
                this.http.post('/api/Land_road/AddLandRoad',rows).then(res=>{
                    console.log(res)
                    if(res.status){
                        this.showLabel = true;
                        this.search()
                    }else{
                        this.$message.error(res.message)

                    }
                })
            }
        },
        saveLable() {
            console.log(this.chek)

            // this.getTagList()
            this.chek.map((item,index)=>{
                item.TagId = item.ID
            })
            this.http.post("/api/Land_road_tag/BindLandRoadTag", {xmid:this.ID,tagList:this.chek}).then((res) => {
                if(res.status){
                    this.search()
                    this.$message.success('绑定成功')
                }else{
                    this.$message.error(res.message)
                }
            })
        },
        deletedLabel(row) {
            console.log(row)
            this.http.post('/api/Land_road_tag_base/DeleteLandRoadTagBase',row).then(res=>{
                if(res.status){
                    this.$message.success('删除成功')
                    this.getTagList()
                    this.search()
                    // this.showColor = false
                    // this.showLabel = false
                }else{
                    this.$message.error(res.message)
                }
            })
        },
        selectLabel(val) {
            console.log(val);
            this.chek = val;
        },
        changeSort(val) {
            if (val.order) {
                this.sortData = {
                    field: val.prop,
                    sort: val.order == "ascending" ? "asc" : "desc",
                };
                if (val.prop == "SSDS") {
                    this.sortData.field = "DSBM";
                }
                if (val.prop == "SSX") {
                    this.sortData.field = "QXBM";
                }
            } else {
                this.sortData = {
                    field: "XH",
                    sort: "asc",
                };
            }
            this.tableData = [];
            this.search();
        },
        tableRowClassName(row, index) {
            return this.checkSelectable(row.row) ? "" : "hidden";
        },
        checkSelectable(row) {
            if (
                (this.formInline.dwmc &&
                    row.DWMC.indexOf(this.formInline.dwmc) < 0) ||
                (this.formInline.xmzrr &&
                    row.XMZRR.indexOf(this.formInline.xmzrr) < 0) ||
                (this.formInline.xmlxr &&
                    row.XMLXR.indexOf(this.formInline.xmlxr) < 0)
            ) {
                return false;
            } else {
                return true;
            }
        },
        DWMCFilter(value, row) {
            console.log(value, row);
        },
        filterTag(value, row, column) {
            console.log(value, row, column);
            return row.operationType === value; //这个返回值就是返回你要展示的内容
        },
        searchCompanyList() {
            this.runArr = [
                { text: this.formInline.dwmc, value: this.formInline.dwmc },
            ];
            console.log(this.multipleSelection);
            var list = [];
            let _search = this.formInline.dwmc.toLowerCase();
            if (_search != "" && _search != undefined) {
                this.searchAll.filter((item) => {
                    if (
                        item.DWMC.toLowerCase().indexOf(_search) !== -1 ||
                        item.XMZRR.toLowerCase().indexOf(_search) !== -1 ||
                        item.XMLXR.toLowerCase().indexOf(_search) !== -1
                    ) {
                        list.push(item);
                        this.companyData = list;
                    }
                });
            } else {
                this.companyData = this.searchAll;
            }
            console.log(this.searchAll);
        },
        //获取已经指派的公司
        getBindedCompany(id) {
            this.http
                .post(
                    "/api/Plan_buildcompany_project/getJsdwByXmid?XMID=" + id,
                    {}
                )
                .then((res) => {
                    console.log(res);
                    for (var j in this.companyData) {
                        for (var i in res.data) {
                            if (res.data[i].jsdwid == this.companyData[j].ID) {
                                this.$refs.multipleTable.toggleRowSelection(
                                    this.companyData[j]
                                );
                            }
                        }
                    }
                    console.log(this.multipleSelection);
                });
        },
        //指派
        bindCompany() {
            console.log(this.XMID);
            console.log(this.multipleSelection);
            var jsdwIDList = [];
            this.multipleSelection.map((item, index) => {
                jsdwIDList.push(item.ID);
            });
            var params = {
                project: {
                    xmid: this.XMID,
                    xmlx: "0",
                },
                jsdwIDList: jsdwIDList,
            };
            this.http
                .post(
                    "/api/Plan_buildcompany_project/AddBuildCompanyProject",
                    params
                )
                .then((res) => {
                    if (res.status) {
                        this.$message.success(res.message);
                        this.getCompanyList();
                    } else {
                        this.$message.error(res.message);
                    }
                });
        },
        //删除
        deleted(item) {
            console.log(item);
            this.http
                .post("/api/Plan_buildcompany/DeleteBuildcompany", item)
                .then((res) => {
                    if (res.status) {
                        this.$message.success(res.message);
                        this.getCompanyList();
                        this.editModel = {};
                        this.$refs.editForm.resetFields();
                        this.showEdit = false;
                    } else {
                        this.$message.error(res.message);
                    }
                });
        },
        //编辑
        edit(item) {
            this.newAdd = false;
            this.title = this.XMMC + "-修改建设单位";
            console.log(item);
            this.editModel = item;
            this.showEdit = true;
            console.log(this.editModel);
        },
        //保存
        saveCompany() {
            console.log(this.editModel);
            this.newAdd
                ? this.add(this.editModel)
                : this.update(this.editModel);
        },
        //新增的
        add(params) {
            params.id = "00000000-0000-0000-0000-000000000000";
            this.http
                .post("/api/Plan_buildcompany/AddBuildcompany?xmlx=0", params)
                .then((res) => {
                    if (res.status) {
                        this.$message.success(res.message);
                        this.getCompanyList();
                        this.editModel = {};
                        this.$refs.editForm.resetFields();
                        this.showEdit = false;
                    } else {
                        this.$message.error(res.message);
                    }
                });
        },
        //编辑的
        update(params) {
            this.http
                .post(
                    "/api/Plan_buildcompany/UpdateBuildcompany?xmlx=0",
                    params
                )
                .then((res) => {
                    if (res.status) {
                        this.$message.success(res.message);
                        this.getCompanyList();
                        this.editModel = {};
                        this.$refs.editForm.resetFields();
                        this.showEdit = false;
                    } else {
                        this.$message.error(res.message);
                    }
                });
        },
        //搜索条件
        searchData() {
            var searchData = {
                page: this.currentPage1,
                rows: this.pageSize1,
                wheres: JSON.stringify([
                    { Name: "XMLX", Value: "Road", DisplayType: "Equal" },
                ]),
            };
            console.log(searchData);
            return searchData;
        },

        addCompany() {
            this.newAdd = true;
            this.editModel = {};
            this.title = this.XMMC + "-新增建设单位";
            this.showEdit = true;
        },
        handleSizeChange1(val) {
            console.log(val);
            this.pageSize1 = val;
        },
        handleCurrentChange1(val) {
            console.log(val);
            this.currentPage1 = val;
        },
        //获取建设单位列表

        getCompanyList() {
            this.http
                .post("/api/Plan_buildcompany/GetPageData", this.searchData())
                .then((res) => {
                    console.log(res);
                    this.total1 = res.total;
                    res.rows.map((item) => {
                        item.show = true;
                    });
                    this.companyData = res.rows;
                    this.searchAll = res.rows;
                    this.getBindedCompany(this.XMID);

                    console.log(this.companyData);
                });
        },
        //选择建设单位
        selectCompany(val) {
            console.log(val);
            this.multipleSelection = val;
        },
        handleChange1() {
            this.viewshow = false;
            this.editshow = true;
            this.btneditshow = true;
            this.btnviewshow = false;
        },
        handleChange2() {
            this.viewshow = true;
            this.editshow = false;
            this.btneditshow = false;
            this.btnviewshow = true;
        },
        submit() {
            this.http
                .post("/api/Plan_road/UpdateRoad", this.formdata)
                .then((res) => {
                    if (res.message == "修改成功") {
                        this.$message("修改成功");
                        this.search();
                        this.openDetails(this.formdata);
                    }
                });
        },
        chechfieldxmlx() {
            return this.gllx == "普通省道" ? false : true;
        },
        checkfieldjsxz() {
            return this.gllx == "高速公路" ? true : false;
        },
        checkfieldxmxz(e) {
            var arr = [];
            if (this.gllx == "高速公路") {
                arr = [
                    "“十三五”续建项目",
                    "2021年新开工项目",
                    "“十四五”其他项目",
                    "“十四五”谋划项目",
                ];
                return arr.indexOf(e) >= 0 ? false : true;
            }
            if (this.gllx == "普通国道") {
                arr = [
                    "“十三五”续建项目",
                    "“十四五”新开工项目",
                    "谋划项目",
                    "“十四五”谋划项目",
                ];
                return arr.indexOf(e) >= 0 ? false : true;
            }
            if (this.gllx == "普通省道") {
                arr = [
                    "“十三五”续建项目",
                    "“十四五”新开工项目",
                    "“十四五”备选项目",
                    // "2021年已安排计划项目",
                    // "2022年建设项目",
                    // "2023年建设项目",
                    // "2024-2025年建设项目",
                ];
                return arr.indexOf(e) >= 0 ? false : true;
            }
        },
        showMaps(row) {
            if (row.isShowPosi) {
                this.dialogTableVisibleData = row;
                this.dialogTableVisibleMap = true;
            }
        },
        openZp(row) {
            console.log(row);
            this.XMID = row.ID;
            this.XMLX = row.XMLX;
            this.XMMC = row.XMMC;
            this.getCompanyList();
            this.assign = true;
        },
        openLct(row) {
            if (row.SFQQXM == "否") {
                return false;
            }
            this.rowlct = row.GLLX;
            this.lctname = row.XMMC;
            this.http
                .post(
                    "/api/Plan_high_national_early/GetData",
                    {
                        page: 1,
                        rows: 1000,
                        Sort: "DSBM",
                        Order: "asc",
                        wheres: JSON.stringify([
                            {
                                Name: "SSWGHXMID",
                                Value: row.ID,
                                DisplayType: "equal",
                            },
                        ]),
                    },
                    "正在请求数据，请稍候..."
                )
                .then((res) => {
                    if (res.status == 0) {
                        if (res.rows.length != 0) {
                            this.SSWXMID = row.ID;
                            this.lct = true;
                        } else {
                            this.$message.warning("该项目暂未绑定前期项目");
                        }
                    }
                });
        },
        openDetails(row) {
            this.activecollapse = "1";
            this.dialogDetails = {};
            this.dialogDetails = row;
            this.dialogTableVisible = true;
            this.formdata = JSON.parse(JSON.stringify(row));
            this.handleChange2();
        },
        exportData() {
            exportData("Plan_road", this.postData());
        },
        getRegion() {
            var postData = { SSDS: "", SSX: "" };
            this.http
                .post("/api/Base_area/getAdminDivTree", postData)
                .then((res) => {
                    // console.log(res);
                    res.map((r) => {
                        r.value = r.label;
                        r.children.map((rr) => {
                            rr.value = rr.label;
                        });
                    });
                    this.regions = res;
                });
            // this.http.post("/api/Plan_road/getRegion", postData).then((res) => {
            //     //遍历地市
            //     res.forEach((v) => {
            //         var item = {};
            //         var qxList = [];
            //         //遍历区县
            //         for (var i = 0; i < v.length; i++) {
            //             var qx = v[i];
            //             //设置地市名称
            //             if (i == 0) {
            //                 item.value = qx.ssds;
            //                 item.label = qx.ssds;
            //                 qxList.push({ value: "", label: "全部" });
            //             }

            //             qxList.push({ value: qx.ssx, label: qx.ssx });
            //             item.children = qxList;
            //         }
            //         this.regions.push(item);
            //     });
            // });
        },
        //查询
        search() {
            this.$refs.table.bodyWrapper.scrollTop = 0; //滚动条回顶部
            this.getPageTotal(); //查汇总
            this.http
                .post(
                    "/api/Plan_road/GetData",
                    this.postData(),
                    "正在请求数据，请稍候..."
                )
                .then((res) => {
                    if (res.status == 0) {
                        this.total = res.total;
                        this.tableData = res.rows;
                         this.tableData.filter((item) => {
                            if (this.noLine.indexOf(item.XMMC) > -1) {
                                item.isShowPosi = false
                            }else{
                                item.isShowPosi = true
                            }
                        });
                    }
                });
        },
        //汇总
        getPageTotal() {
            this.http
                .post("/api/Plan_road/GetPageTotal", this.postData())
                .then((res) => {
                    this.xmsl = res.xmsl;
                    this.jsgm = res.jsgm;
                    this.jsqmqs = res.jsqmqs;
                    this.ztz = res.ztz;
                    this.sswtz = res.sswtz;
                });
        },
        postData() {
            var ssds = "";
            var ssx = "";
            if (this.region.length > 0) {
                ssds = this.region[0];
                if (this.region.length > 1) {
                    ssx = this.region[1];
                }
            }

            //项目名称
            var query_xmmc = {
                Name: "XMMC",
                Value: this.xmmc,
                DisplayType: "like",
            };
            //所属地市
            var query_ssds = {
                Name: "SSDS",
                Value: ssds.indexOf('市')!== -1 ?ssds.replace('市','') : ssds,
                DisplayType: "like",
            };
            //所属县
            var query_ssx = {
                Name: "SSX",
                Value: ssx.indexOf('市')!== -1 ?ssx.replace('市','') : ssx.indexOf('区')!== -1 ? ssx.replace('区','') :ssx.indexOf('县')!== -1 ?  ssx.replace('县','') : ssx,
                DisplayType: "like",
            };
            //项目年度
            var query_xmnd = {
                Name: "XMND",
                Value: this.xmnd,
                DisplayType: "like",
            };

            //前期工作进展
            var qqgzjzArray = [];
            this.qqgzjz.forEach((element) => {
                if (element.length > 0) {
                    qqgzjzArray.push(element[0]);
                    //当前期工作为进行中时，包括两个状态，用|分隔，保存时同样采用此方式
                    if (element.length > 1) {
                        qqgzjzArray.push(element[0] + "|" + element[1]);
                    }
                }
            });
            var query_qqgzjz = {
                Name: "QQGZJZ",
                Value: qqgzjzArray.join(","),
                DisplayType: "checkbox",
            };

            //建设类别
            var query_gllx = {
                Name: "GLLX",
                Value: this.gllx,
                DisplayType: "Equal",
            };

            //G331特殊处理
            if (this.gllx == "G331") {
                query_gllx = {
                    Name: "LXBH",
                    Value: this.gllx,
                    DisplayType: "Equal",
                };
            }

            //项目性质
            var xmxzArray = [];
            this.checkedXmxz.forEach((element) => {
                xmxzArray.push(element);
            });
            var query_xmxz = {
                Name: "XMXZ",
                Value: xmxzArray.join(","),
                DisplayType: "checkbox",
            };

            //建设性质
            var query_jsxz = {
                Name: "JSXZ",
                Value: this.checkedJsxz.join(","),
                DisplayType: "checkbox",
            };

            //项目类型
            var xmlxArray = [];
            this.checkedXmlx.forEach((element) => {
                xmlxArray.push(element);
            });
            var query_xmlx = {
                Name: "XMLX",
                Value: xmlxArray.join(","),
                DisplayType: "checkbox",
            };
            // var xmgnArray = [];
            // this.checkedXmgn.forEach((element) => {
            //     // xmlxArray.push(this.tranXmlx(element));
            //     xmgnArray.push(element);
            // });
            var query_xmgn = {
                Name: "XMGN",
                Value: this.checkedXmgn.join(","),
                DisplayType: "checkbox",
            };
            //标签
            var labelArray = [];
            var query_xmbq = {
                Name: "TagId",
                Value: this.checkedLabel.join(","),
                DisplayType: "checkbox",
            };
            // this.checkedLabel.forEach((element) => {
            //     element = "'"+element+"'"
            //     labelArray.push(element);
            // });
            if(this.gllx === ''){
                this.sortData.field = 'GLLX,XH'
                this.sortData.sort = 'desc,asc'
            }else{
                this.sortData.field = 'XH'
                this.sortData.sort = 'asc'
            }
            var postData = {
                page: this.currentPage,
                rows: this.pageSize,
                Sort: this.sortData.field,
                Order: this.sortData.sort,
                Value:'',
                wheres: JSON.stringify([
                    query_xmbq,
                    query_xmgn,
                    query_xmmc,
                    query_ssds,
                    query_ssx,
                    query_xmnd,
                    query_qqgzjz,
                    query_gllx,
                    query_xmxz,
                    query_jsxz,
                    query_xmlx,
                ]),
            };
            return postData;
        },
        clearCondition() {
            this.region = [""];
            this.xmmc = "";
            this.xmnd = "";
            this.qqgzjz = [];
            this.gllx = "";
            this.checkedXmxz = [];
            this.checkedJsxz = [];
            this.checkedXmgn = [];
            this.checkedXmlx = [];
        },
        //公路现状汇总
        getRoadTotal() {
            var ds = "";
            var qx = "";
            if (this.region.length > 0) {
                ds = this.region[0];
                if (this.region.length > 1) {
                    qx = this.region[1];
                }
            }
            //所属地市
            var query_ssds = {
                Name: "Ds",
                Value: ds,
                DisplayType: "like",
            };
            //所属县
            var query_ssx = {
                Name: "Qx",
                Value: qx,
                DisplayType: "like",
            };

            var postData = {
                wheres: JSON.stringify([query_ssds, query_ssx]),
            };
            //汇总
            this.http
                .post("/api/Base_road/GetTotal", postData)
                .then((total) => {
                    this.total_glxz_xzdj = total.xzdj;
                    this.total_glxz_jsdj = total.jsdj;
                    this.total_glxz_gsdjgl = total.gldj.filter(
                        (v) => v.xzdj == "G" || v.xzdj == "S"
                    );
                    this.total_glxz_xycdjgl = total.gldj.filter(
                        (v) => v.xzdj == "X" || v.xzdj == "Y" || v.xzdj == "C"
                    );
                });
        },
        //拆分建设性质
        getTotal() {
            //汇总
            console.log(this.postData());
            this.http
                .post("/api/Plan_road/GetTotal", this.postData())
                .then((total) => {
                    this.totalJsxz = total.jsxz;
                    this.totalXmxz = total.xmxz;
                    this.totalXzqh = total.xzqh;
                    this.totalXmxz.forEach((res, index) => {
                        res.xmsl = 0;
                        res.jsgm = 0;
                        res.jsgm_qs = 0;
                        res.ztz = 0;
                        res.sswtz = 0;
                        res.children = res.xmxz;
                        res.xmxz = "";
                        res.id = (index + 1) * 10;
                        res.children.forEach((v, i) => {
                            v.id = parseInt(index + 1 + "" + (i + 1));
                            v.ssds = "";
                            v.jsgm =
                                v.jsgm_ej +
                                v.jsgm_ej60 +
                                v.jsgm_gs +
                                v.jsgm_sj +
                                v.jsgm_yj;
                            res.jsgm += v.jsgm;
                            res.ztz += v.ztz;
                            res.sswtz += v.sswtz;
                            res.jsgm_qs += v.jsgm_qs;
                            res.xmsl += v.xmsl;
                        });
                    });
                    this.totalJsxz.forEach((res, index) => {
                        res.xmsl = 0;
                        res.jsgm = 0;
                        res.jsgm_qs = 0;
                        res.ztz = 0;
                        res.sswtz = 0;
                        res.children = res.jsxz;
                        res.id = (index + 1) * 10;
                        res.children.forEach((v, i) => {
                            v.id = parseInt(index + 1 + "" + (i + 1));
                            v.ssds = "";
                            v.jsgm =
                                v.jsgm_ej +
                                v.jsgm_ej60 +
                                v.jsgm_gs +
                                v.jsgm_sj +
                                v.jsgm_yj;
                            res.jsgm += v.jsgm;
                            res.ztz += v.ztz;
                            res.sswtz += v.sswtz;
                            res.jsgm_qs += v.jsgm_qs;
                            res.xmsl += v.xmsl;
                        });
                    });

                    this.totalXzqh.forEach((element) => {
                        element.jsgm =
                            (element.jsgm_gs != null ? element.jsgm_gs : 0) +
                            (element.jsgm_yj != null ? element.jsgm_yj : 0) +
                            (element.jsgm_ej != null ? element.jsgm_ej : 0) +
                            (element.jsgm_sj != null ? element.jsgm_sj : 0);
                    });
                });
        },
        handleChange(value) {
            // this.checkedXmxz = [];
            // this.search();
        },
        handleCheckAllXmxzChange(val) {
            if (val) {
                if (this.gllx == "高速公路") {
                    this.checkedXmxz = [
                        "“十三五”续建项目",
                        "2021年新开工项目",
                        "“十四五”其他项目",
                        "“十四五”谋划项目",
                    ];
                } else if (this.gllx == "普通国道") {
                    this.checkedXmxz = [
                        "“十三五”续建项目",
                        "“十四五”新开工项目",
                        "谋划项目",
                        "“十四五”谋划项目",
                    ];
                } else if (this.gllx == "普通省道") {
                    this.checkedXmxz = [
                        "“十三五”续建项目",
                        "2021年已安排计划项目",
                        "2022年建设项目",
                        "2023年建设项目",
                        "2024-2025年建设项目",
                    ];
                } else {
                    this.checkedXmxz = this.xmxzList;
                }
            } else {
                this.checkedXmxz = [];
            }
            this.isIndeterminateXmxz = false;
        },
        handleCheckAllJsxzChange(val) {
            this.checkedJsxz = val ? this.jsxzList : [];
            this.isIndeterminateJsxz = false;
        },
        handleCheckAllXmgnChange(val) {
            this.checkedXmgn = val ? this.xmgnList : [];
            this.isIndeterminateXmlx = false;
        },
        handleCheckAllXmlxChange(val) {
            if (this.gllx == "普通省道") {
                this.checkedXmlx = val ? this.xmlxList : [];
            }
            this.isIndeterminateXmlx = false;
        },
        handleCheckedXmxzChange(value) {
            let checkedCount = value.length;
            this.checkAllXmxz = checkedCount === this.xmxzList.length;
            this.isIndeterminateXmxz =
                checkedCount > 0 && checkedCount < this.xmxzList.length;
        },
        handleCheckedJsxzChange(value) {
            let checkedCount = value.length;
            this.checkAllJsxz = checkedCount === this.jsxzList.length;
            this.isIndeterminateJsxz =
                checkedCount > 0 && checkedCount < this.jsxzList.length;
        },
        handleCheckedXmlxChange(value) {
            let checkedCount = value.length;
            this.checkAllXmlx = checkedCount === this.xmlxList.length;
            this.isIndeterminateXmlx =
                checkedCount > 0 && checkedCount < this.xmlxList.length;
        },
        handleCheckedXmgnChange(value) {
            console.log(value);
            let checkedCount = value.length;
            this.checkAllXmgn = checkedCount === this.xmgnList.length;
            this.isIndeterminateXmgn =
                checkedCount > 0 && checkedCount < this.xmgnList.length;
        },
        handleSizeChange(val) {
            console.log(this.companyPage);
            this.pageSize = val;
            this.search();
        },
        handleCurrentChange(val) {
            console.log(this.companyPage);
            this.currentPage = val;
            this.search();
        },
    },
    watch: {
        activeIndex(v) {
            if (v) {
                this.tableHeight = document.body.offsetHeight - 504;
            } else {
                this.tableHeight = document.body.offsetHeight - 304;
            }
        },
    },
};
</script>

<style scoped lang="less">
    .flex{
        display: flex;
        align-items: center;
    }
    .fl-jus {
        margin-bottom: 4px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .label {
        margin-right: 8px;
        width: 126px;
        height: 42px;
        background: #fafafa;
        line-height: 42px;
        color: #000000;
        font-weight: 600;
        text-align: center;
    }
    .label2 {
        width: 145px;
        height: 36px;
        background: #fafafa;
        line-height: 36px;
        color: #000000;
        font-weight: 600;
        text-align: center;
    }
    .label3 {
        width: 478px;
        height: 36px;
        background: #fafafa;
        line-height: 36px;
        color: #000000;
        font-weight: 600;
        text-align: center;
    }
    .cont {
        width: 220px;
        height: 36px;
        text-align: center;
        line-height: 36px;
    }
    .labels {
        margin: 0 auto;
        width: 27px;
        height: 20px;
        position: relative;
        border-radius: 2px;
        &::after {
            content: "";
            width: 0;
            height: 0;
            border-radius: 2px;
            border-top: 10px solid transparent;
            border-right: 8px solid #fff;
            border-bottom: 10px solid transparent;
            position: absolute;
            top: 0;
            left: 19px;
        }
    }
    /deep/.el-color-picker {
        line-height: 60px;
    }
    /deep/.el-color-picker__color {
        border: none;
    }

    /deep/.el-color-picker__trigger {
        margin: 0 auto;
        width: 35px;
        height: 20px;
        position: relative;
        border-radius: 2px;
        padding: 0;
        border: none;
        &::after {
            content: "";
            width: 0;
            height: 0;
            border-radius: 2px;
            border-top: 10px solid transparent;
            border-right: 8px solid #fff;
            border-bottom: 10px solid transparent;
            position: absolute;
            top: 0;
            right: 0px;
        }
    }
    .bigLabels {
        margin-right: 13px;
        padding: 0px 15px 0px 6px;
        height: 20px;
        line-height: 20px;
        position: relative;
        border-radius: 2px;
        color: #ffffff;
        margin-top: 4px;
        &::after {
            content: "";
            width: 0;
            height: 0;
            border-radius: 2px;
            border-top: 10px solid transparent;
            border-right: 8px solid #fff;
            border-bottom: 10px solid transparent;
            position: absolute;
            top: 0;
            right: 0px;
        }
    }
    .dialog-footer {
        width: 100%;
        height: 47px;
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        background: #f8f8f8;
        align-items: center;
        justify-content: center;
    }
    .edit {
        padding-right: 15px;
        border-right: 1px solid rgba(112, 112, 112, 0.1);
        font-size: 16px;
        color: #409eff;
    }
    .delete {
        font-size: 16px;
        color: #f56c6c;
    }
    ::v-deep .mapdialog1 .el-dialog{
        margin-top: 0 !important;

    }
    ::v-deep .el-dialog{
        /*margin-top: 0 !important;*/

    }
</style>
