<template>
    <el-container>
        <el-main>
            <el-row>
                <el-col :span="24">
                    <h3 class="tit">项目性质</h3>
                    <el-table :data="totalXmxz" ref="theTable1" size="small" style="width: 100%;margin-bottom: 20px;" row-key="id" border default-expand-all :default-sort="{ prop: 'jsxz', order: 'descending' }" :tree-props="{children: 'children',hasChildren: 'hasChildren'}">
                        <el-table-column prop="ssds" label="所属地市"></el-table-column>
                        <el-table-column prop="xmxz" label="项目性质" :formatter="xmxzformatter"></el-table-column>
                        <el-table-column prop="xmsl" label="项目数量（个）" width="120"></el-table-column>
                        <el-table-column prop="jsgm" label="建设规模（公里）" width="140" :formatter="formatter"></el-table-column>
                        <el-table-column prop="jsgm_qs" label="桥隧（延米）" width="120"></el-table-column>
                        <el-table-column prop="ztz" label="总投资（亿元）" :formatter="ztz_formatter" width="130"></el-table-column>
                        <el-table-column prop="sswtz" label="十四五投资（亿元）" :formatter="sswtz_formatter" width="150"></el-table-column>
                    </el-table>
                    <!-- <el-table :data="totalXmxz" size="small " :default-sort="{ prop: 'xmxz', order: 'descending' }" border style="width: 100%">
                        <el-table-column prop="xmxz" label="项目性质" :formatter="xmxzformatter">
                        </el-table-column>
                        <el-table-column prop="xmsl" label="项目数量（个）" width="120">
                        </el-table-column>
                        <el-table-column prop="jsgm" label="建设规模（公里）" width="140" :formatter="formatter">
                        </el-table-column>
                        <el-table-column prop="jsgm_qs" label="桥隧（延米）" width="120">
                        </el-table-column>
                        <el-table-column prop="ztz" label="总投资（亿元）" :formatter="ztz_formatter" width="130">
                        </el-table-column>
                        <el-table-column prop="sswtz" label="十四五投资（亿元）" :formatter="sswtz_formatter" width="150">
                        </el-table-column>
                    </el-table> -->
                </el-col>
                <el-col :span="24">
                    <h3 class="tit">建设性质</h3>
                    <el-table :data="totalJsxzs" ref="theTable" size="small" style="width: 100%;margin-bottom: 20px;" row-key="id" border default-expand-all :default-sort="{ prop: 'jsxz', order: 'descending' }" :tree-props="{children: 'children',hasChildren: 'hasChildren'}">
                        <el-table-column prop="ssds" label="所属地市"></el-table-column>
                        <el-table-column prop="key" label="建设性质" :formatter="jsxzformatter"></el-table-column>
                        <el-table-column prop="xmsl" label="项目数量（个）" width="120"></el-table-column>
                        <el-table-column prop="jsgm" label="建设规模（公里）" width="140" :formatter="formatter"></el-table-column>
                        <el-table-column prop="jsgm_qs" label="桥隧（延米）" width="120"></el-table-column>
                        <el-table-column prop="ztz" label="总投资（亿元）" :formatter="ztz_formatter" width="130"></el-table-column>
                        <el-table-column prop="sswtz" label="十四五投资（亿元）" :formatter="sswtz_formatter" width="150"></el-table-column>
                    </el-table>
                    <!-- <el-table :data="totalJsxz" size="small " :default-sort="{ prop: 'jsxz', order: 'descending' }" border style="width: 100%">
                        <el-table-column prop="jsxz" label="建设性质" :formatter="jsxzformatter">
                        </el-table-column>
                        <el-table-column prop="xmsl" label="项目数量（个）" width="120">
                        </el-table-column>
                        <el-table-column prop="jsgm" label="建设规模（公里）" width="140" :formatter="formatter">
                        </el-table-column>
                        <el-table-column prop="jsgm_qs" label="桥隧（延米）" width="120">
                        </el-table-column>
                        <el-table-column prop="ztz" label="总投资（亿元）" :formatter="ztz_formatter" width="130">
                        </el-table-column>
                        <el-table-column prop="sswtz" label="十四五投资（亿元）" :formatter="sswtz_formatter" width="150">
                        </el-table-column>
                    </el-table> -->
                </el-col>
                <el-col :span="24">
                    <h3 class="tit">行政区划</h3>
                    <el-table :data="totalXzqh" size="small " :default-sort="{ prop: 'xzqh', order: 'descending' }" border style="width: 100%">
                        <el-table-column prop="xzqh" label="行政区划" :formatter="xzqhformatter">
                        </el-table-column>
                        <el-table-column prop="xmsl" label="项目数量（个）" width="120">
                        </el-table-column>
                        <el-table-column prop="jsgm" label="建设规模（公里）" width="140" :formatter="formatter">
                        </el-table-column>
                        <el-table-column prop="jsgm_qs" label="桥隧（延米）" width="120">
                        </el-table-column>
                        <el-table-column prop="ztz" label="总投资（亿元）" :formatter="ztz_formatter" width="130">
                        </el-table-column>
                        <el-table-column prop="sswtz" label="十四五投资（亿元）" :formatter="sswtz_formatter" width="150">
                        </el-table-column>
                    </el-table>
                </el-col>
            </el-row>
        </el-main>
    </el-container>
</template>
<script>
export default {
    props: ["totalXmxz", "totalJsxz", "totalXzqh"],
    data() {
        return {
            totalJsxzs: [],
            totalXmxzs: [],
        };
    },
    mounted() {
        this.totalJsxzs = this.totalJsxz;
        this.totalXmxzs = this.totalXmxz;
    },

    watch: {
        totalJsxz(e) {
            this.totalJsxzs = e;
            var that = this;
            setTimeout(() => {
                that.totalJsxzs.forEach((i) => {
                    that.$refs.theTable.toggleRowExpansion(i, false);
                });
            }, 100);
        },
        totalXmxz(e) {
            this.totalXmxzs = e;
            var that = this;
            setTimeout(() => {
                that.totalXmxzs.forEach((i) => {
                    that.$refs.theTable1.toggleRowExpansion(i, false);
                });
            }, 100);
        },
    },
    methods: {
        handleClose(done) {
            done();
        },
        formatter(row) {
            return parseFloat(row.jsgm).toFixed(1);
        },
        xmxzformatter(row) {
            if (row.xmxz == null || row.xmxz == "") {
                return "未分类";
            }
            return row.xmxz;
        },
        jsxzformatter(row) {
            if (row.key == null || row.key == "") {
                return "未分类";
            }
            return row.key;
        },
        xzqhformatter(row) {
            if (row.xzqh == null || row.xzqh == "") {
                return "未分类";
            }
            return row.xzqh;
        },
        ztz_formatter(row) {
            return parseFloat(row.ztz).toFixed(1);
        },
        sswtz_formatter(row) {
            return parseFloat(row.sswtz).toFixed(1);
        },
    },
};
</script>
<style scoped>
</style>
